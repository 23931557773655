
const scrollInit = ()=> {
    const navScrollInit = () => {
        window.addEventListener('scroll', function () {
            const navigation = document.querySelector('.header__menu-image');
            if (navigation === null) return;
            if (window.scrollY > navigation.offsetHeight) {
                navigation.classList.add('fixed');
            } else {
                navigation.classList.remove('fixed');
            }
        });
    }

    return {
        navScrollInit,
    }
}

export default scrollInit;
